<template>
  <section id="dashboard-ecommerce">

    <b-row class="match-height">
      <!--/ Company Table Card -->
      <b-col lg="12">
        <b-card>
          <b-card-body>
            <b-card-title>Clients</b-card-title>


            <b-table :fields="userFields" :items="user" responsive class="mb-0">
              <!-- A custom formatted column -->
              <template #cell(_signature)="data">
                <span class="text-nowrap">{{
                  data.value.substring(0, 28) + "..."
                }}</span>
              </template>

              <template #cell(status)="data">
                <b-badge pill :variant="data.value.variant">
                  {{ data.value.status }}
                </b-badge>
              </template>

              <template #cell(_owner_or_delegate)="data">
                <b-badge pill :variant="data.value.variant">
                  {{ data.value.status }}
                </b-badge>
              </template>
              <template #cell()="data">
                {{ data.value }}
              </template>

              <template #cell(id)="data">
                <a :href="`/log/${data.item.id}`">
                  <feather-icon icon="LinkIcon" size="24" />
                </a>
                {{ data.value }}
              </template>

            </b-table>
          </b-card-body>
        </b-card>

        <b-card class="mt-4">
          <b-card-body>
            <b-card-title>Explorer</b-card-title>

            <b-row align-h="between">
              <b-col md="4" sm="8">
              </b-col>
              <b-col md="3" class="align-right">
                <b-form @submit="searchData">
                  <b-form-group>
                    <b-input-group size="sm">
                      <b-form-input
                          id="filterInput"
                          v-model="logSearchInput"
                          type="search"
                          placeholder="Rechercher un Block ou un client"
                      />
                      <b-input-group-append>
                        <b-button type="submit">Rechercher</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-form>
              </b-col>
            </b-row>
            <b-table :fields="logFields" :items="logs" responsive class="mb-0">
              <!-- A custom formatted column -->
              <template #cell(_signature)="data">
                <span class="text-nowrap">{{
                    data.value.substring(0, 28) + "..."
                  }}</span>
              </template>

              <template #cell(status)="data">
                <b-badge pill :variant="data.value.variant">
                  {{ data.value.status }}
                </b-badge>
              </template>

              <template #cell(_owner_or_delegate)="data">
                <b-badge pill :variant="data.value.variant">
                  {{ data.value.status }}
                </b-badge>
              </template>
              <template #cell()="data">
                {{ data.value }}
              </template>

              <template #cell(id)="data">
                <a :href="`/log/${data.item.id}`">
                  <feather-icon icon="LinkIcon" size="24" />
                </a>
                {{ data.value }}
              </template>

              <template #cell(details)="row">
                <b-button @click="row.toggleDetails" variant="flat-secondary">
                  {{ row._showDetails ? "Détails" : "Détails" }}
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col
                        v-for="(key, idx) in Object.keys(row.item).filter(x => !hideFields.includes(x))"
                        :key="idx"
                        md="4"
                        class="mb-1"
                    >
                      <strong>{{ key }} : </strong>{{ row.item[key] }}
                      <b-button
                          v-if="key === '_certificate'"
                          @click.prevent="downloadCertificate(row.item._id)"
                          variant="primary"
                      >
                        Télécharger votre attestation
                      </b-button>
                      <b-button
                          v-if="key === '_qrcode'"
                          @click.prevent="downloadQRCode(row.item._id)"
                          variant="primary"
                      >
                        Télécharger votre QRCode
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click="row.toggleDetails"
                  >
                    Masquer les détails
                  </b-button>
                </b-card>
              </template>


            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BTable,
  BBadge,
  BCardHeader,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";

import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BBadge,
    BCard,
    BCardBody,
    BCardTitle,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      totalLogsNb: 0,
      peersConnected: 0,
      outOfSyncInterval: 0,
      logSearchInput: "",
      realtimeLogsUpdate: true,
      lastBlockId: null,
      // Champs masqués dans les détails d'un log
      hideFields: ['status', '_showDetails', 'id', '_owner_or_delegate'],
      // rangePicker: ["2019-05-01", "2019-05-10"],

      userFields: [
        // A column that needs custom formatting
        { key: "_id", label: "ID" },
        { key: "email", label: "Email" },
        { key: "firstname", label: "Prénom" },
        { key: "lastname", label: "Nom" },
        { key: "company", label: "Société" },
        { key: "logCount", label: "Nombre de Logs" },
      ],

      logFields: [
        // A column that needs custom formatting
        { key: "_id", label: "# Block" },
        { key: "_timestamp", label: "Horodatage" },
        { key: "_signature", label: "Signature" },
        { key: "status", label: "Statut" },
        { key: "_owner_or_delegate", label: "Type signature" },
        { key: "details", label: "Détails" },
      ],
      user: [],
      logs: [],
      privateKey: "",
      pubKey: "",
    };
  },
  watch: {
    realtimeLogsUpdate: function () {
      if (this.realtimeLogsUpdate) {
        this.lastBlockId = null;
        this.logSearchInput = "";
        this.updateLastLogs();
      }
    },
  },

  mounted() {
    this.privateKey = localStorage.getItem('privateKey') ;
    this.publicKey = localStorage.getItem('publicKey') ;
    this.fetchLogsPerUser();
  },
  computed: {
    hideDownloadCertificateBtn() {
      return (window.location.href.indexOf("openlogs.fr") > -1);
    }
  },
  methods: {
    downloadCertificate(id) {
      axios.get(`https://sb.api.dev.shurisko.fr/api/logs/${id}/certificate`, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then(({ data }) => {
        const a = document.createElement("a");
        a.href = "data:application/octet-stream;base64,"+data.data;
        a.download = `attestation-openlogs.pdf`;
        a.click();
      });
    },
    downloadQRCode(id) {
      axios.get(`https://sb.api.dev.shurisko.fr/api/logs/${id}/qrcode`, {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        }
      }).then(({ data }) => {
        const a = document.createElement("a");
        a.href = "data:application/octet-stream;base64,"+data.data;
        a.download = `openlogs-qrCode.png`;
        a.click();
      });
    },
    searchData(e) {
      e.preventDefault();

      axios.get("https://sb.api.dev.shurisko.fr/api/admin/log", {
        headers: {
          "X-OPENLOGS-PUBKEY": this.publicKey,
          "X-OPENLOGS-PRIVKEY": this.privateKey
        },
        params: {
          id: this.logSearchInput
        }
      }).then((data) => {
        console.log(data.data.data)
        this.logs = [];
        for (const logIterator of data.data.data) {
          let log = logIterator.content;
          log._id = logIterator._id
          log._showDetails = false;
          log._timestamp = new Date(log._timestamp).toUTCString();

          log._owner_or_delegate = log._owner == log._author
              ? { status: "Propriétaire", variant: "success" }
              : { status: "Déléguée", variant: "info" };

          log.status = log._signature
              ? { status: "Vérifié", variant: "success" }
              : { status: "N/C", variant: "danger" };
          this.logs.push(log);
        }
      })
    },
    fetchLogsPerUser() {
      this.realtimeLogsUpdate = false;
      axios
        .get(`https://sb.api.dev.shurisko.fr/api/admin/users`, {
          headers: {
            "X-OPENLOGS-PUBKEY": this.publicKey,
            "X-OPENLOGS-PRIVKEY": this.privateKey
          }
        })
        .then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            this.user = res.data.data;
          } else {
            alert(`Aucun résultat pour #${this.logSearchInput}`);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style lang="scss" scoped>
.transaction-item .media {
  align-items: center;
}
</style>
